import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: "AIzaSyAv3RwMxTrd-XVxZ7-FQVy5OZeVEXhX4b8",
  authDomain: "carrat-b291f.firebaseapp.com",
  projectId: "carrat-b291f",
  storageBucket: "carrat-b291f.appspot.com",
  messagingSenderId: "1073850176812",
  appId: "1:1073850176812:web:1c429070ea1706e4630889",
  measurementId: "G-JZTEQKDGS9"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
export const storage = getStorage(app);
