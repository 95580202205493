import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { collection, onSnapshot } from "firebase/firestore";
import { userColumns } from "../../../datatablesource";
import { db } from "../../../firebase"; // Import your Firebase and Firestore configurations

const Datatable = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const unsub = onSnapshot(
      collection(db, "deleted_accounts"),
      async (snapShot) => {
        const list = await Promise.all(
          snapShot.docs.map(async (doc) => {
            const data = { id: doc.id, ...doc.data() };
            return { ...data };
          })
        );
        setData(list);
      },
      (error) => {
        console.error("Error fetching data:", error);
      }
    );

    return () => {
      unsub();
    };
  }, []);

  return (
    <div className="datatable">
      <DataGrid
        className="datagrid"
        rows={data}
        columns={[
          ...userColumns
        ]}
        pageSize={50}
        rowsPerPageOptions={[50]}
        checkboxSelection
      />
    </div>
  );
};

export default Datatable;
