import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { collection, onSnapshot } from "firebase/firestore";
import { recordingsColumns } from "../../../datatablesource";
import { getDownloadURL, ref as storageRef } from "firebase/storage";
import { db, storage } from "../../../firebase"; // Import your Firebase and Firestore configurations

const Datatable = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const unsub = onSnapshot(
      collection(db, "recordings"),
      async (snapShot) => {
        const list = await Promise.all(
          snapShot.docs.map(async (doc) => {
            const recordingData = { id: doc.id, ...doc.data() };
            const url = await getDownloadURL(storageRef(storage, recordingData.url));
            return { ...recordingData, url };
          })
        );
        setData(list);
      },
      (error) => {
        console.error("Error fetching data:", error);
      }
    );

    return () => {
      unsub();
    };
  }, []);

  return (
    <div className="datatable">
      <DataGrid
        className="datagrid"
        rows={data}
        columns={[
          ...recordingsColumns,
          {
            field: "audio",
            headerName: "Audio",
            width: 510,
            renderCell: (params) => {
              const { url } = params.row;
              return (
                <div>
                  {url ? (
                    <div>
                      <audio controls>
                        <source src={url} type="audio/mpeg" />
                        Your browser does not support the audio element.
                      </audio>
                    </div>
                  ) : (
                    "No audio URL available"
                  )}
                </div>
              );
            },
          },
          // Add other columns as needed
        ]}
        pageSize={50}
        rowsPerPageOptions={[50]}
        checkboxSelection
      />
    </div>
  );
};

export default Datatable;
