import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns} from "../../datatablesource";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  collection,
  // deleteDoc,
  doc,
  onSnapshot,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { CSVLink } from "react-csv"; // Import CSVLink

const Datatable = () => {
  const [data, setData] = useState([]);

  useEffect(() => {

    // LISTEN (REALTIME)
    const unsub = onSnapshot(
      collection(db, "users"),
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setData(list);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
  }, []);

  const exportData = () => {
    // Prepare the data for CSV export
    const csvData = data.map((row) => ({
      ID: row.id,
      Name: row.name,
      Email: row.email,
      Phone: row.phone,
      totalCallCost: row.totalCallPrice,
      Language: row.user_language,
      Account: row.active,
      LiveCall: row.liveCall,
      LiveRecording: row.liveRecording,
      // Add other fields you want to export here
    }));

    return csvData;
  };

  // const handleDelete = async (id) => {
  //   try {
  //     await deleteDoc(doc(db, "users", id));
  //     setData(data.filter((item) => item.id !== id));
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const handleLiveCallToggle = async (id) => {
    try {
        const userRef = doc(db, "users", id);
        const userData = await getDoc(userRef);

        if (userData.exists()) {
            const currentCallValue = userData.data().liveCall || false;
            await updateDoc(userRef, {
                liveCall: !currentCallValue,
                liveRecording: currentCallValue  // If liveCall was true, set liveRecording to false and vice versa
            });
        } else {
            console.warn(`Document with ID ${id} does not exist.`);
        }
    } catch (error) {
        console.log("Error updating liveCall status:", error);
    }
  }

  // const handleAccountActivationToggle = async (id) => {
  //   try {
  //       const userRef = doc(db, "users", id);
  //       const userData = await getDoc(userRef);

  //       if (userData.exists()) {
  //           const currentActiveValue = userData.data().active;

  //           // Create a custom confirmation message
  //           const confirmationMessage = currentActiveValue
  //               ? "Do you want to deactivate this account?"
  //               : "Do you want to activate this account?";

  //           // Show the confirmation popup
  //           const confirmation = window.confirm(confirmationMessage);

  //           if (confirmation) {
  //               // Toggle the account activation status
  //               await updateDoc(userRef, {
  //                   active: !currentActiveValue,
  //               });
  //           } else {
  //               // User canceled the operation
  //               console.log("User canceled the operation.");
  //           }
  //       } else {
  //           console.warn(`Document with ID ${id} does not exist.`);
  //       }
  //   } catch (error) {
  //       console.log("Error updating Active status:", error);
  //   }
  // }

  const handleLiveRecordingToggle = async (id) => {
      try {
          const userRef = doc(db, "users", id);
          const userData = await getDoc(userRef);

          if (userData.exists()) {
              const currentRecordingValue = userData.data().liveRecording || false;
              await updateDoc(userRef, {
                  liveRecording: !currentRecordingValue,
                  liveCall: currentRecordingValue  // If liveRecording was true, set liveCall to false and vice versa
              });
          } else {
              console.warn(`Document with ID ${id} does not exist.`);
          }
      } catch (error) {
          console.log("Error updating liveRecording status:", error);
      }
  }


  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to={`/users/${params.row.id}`} style={{ textDecoration: "none" }}>
              <div className="viewButton">View</div>
            </Link>
            {/* <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div> */}
          </div>
        );
      },
    },
    {
      field: "liveCall",
      headerName: "Live Call",
      width:100,
      renderCell: (params)=>{
        return(
          <div className="cellAction">
            <label className="switch">
              <input type="checkbox"
              className="input"
              checked={!!params.row.liveCall && !params.row.liveRecording} // Set the checkbox state
              onChange={()=>handleLiveCallToggle(params.row.id)}
              ></input>
              <span className="slider"></span>
            </label>
          </div>
        );
      },
    },
    {
      field: "liveRecording",
      headerName: "Live Recording",
      width:100,
      renderCell: (params)=>{
        return(
          <div className="cellAction">
            <label className="switch">
              <input type="checkbox"
              className="input"
              checked={!!params.row.liveRecording && !params.row.liveCall} // Set the checkbox state
              onChange={()=> handleLiveRecordingToggle(params.row.id)}
              ></input>
              <span className="slider"></span>
            </label>
          </div>
        );
      },
    },
  ];
  return (
    <div className="datatable">
      <DataGrid
        className="datagrid"
        rows={data}
        columns={userColumns.concat(actionColumn)}
        pageSize={50}
        rowsPerPageOptions={[50]}
        checkboxSelection
      />
      <CSVLink
        data={exportData()}
        filename={"user_data.csv"} // Specify the filename
        className="export-button"
        target="_blank"
      >
        Export to CSV
      </CSVLink>
    </div>
  );
};

export default Datatable;
