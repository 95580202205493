import moment from 'moment-timezone';
export const userColumns = [
  { field: "id", headerName: "ID", width: 100 },
  {
    field: "user",
    headerName: "User",
    width: 150,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          {/* <img className="cellImg" src={params.row.img} alt="avatar" /> */}
          {params.row.name}
        </div>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",
    width: 230,
  },

  {
    field: "phone",
    headerName: "Phone",
    width: 150,
  },
  {
    field: "totalCallPrice",
    headerName: "Total Call Price",
    width: 200,
  },
  {
    field: "active",
    headerName: "Active",
    width: 100,
  },
  {
    field: "user_language",
    headerName: "Language",
    width: 100,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.user_language}`}>
          {params.row.user_language}
        </div>
      );
    },
  },
];

export const recordingsColumns = [
  { field: "uid", headerName: "ID", width: 300 },
  { field: "url", headerName: "URL", width: 300,  renderCell: (params)=>{
      return `${params.row.url}.mp3`;
    },
  },
  { field: "created_at", headerName: "Created At", width: 300, renderCell: (params)=>{
    // Usage
    const firestoreTimestamp = params.row.created_at;
    const date = firestoreTimestamp.toDate();

    const zurichDate = moment(date).tz('Europe/Zurich');
    const formattedDate = zurichDate.format('ddd, MMM DD, YYYY, hh:mm:ss A');

    // Determine if the time should be in CET or CEST
    const timezoneSuffix = isCET(zurichDate) ? ' CEST': ' CET';
    return formattedDate + timezoneSuffix;
  }},
]
function isCET(mDate) {
  // Returns true if the date is NOT in daylight saving time, and thus should be in CET
  return !mDate.isDST();
}

export const lastCallsColums = [
  { field: "id", headerName: "ID", width: 310 },
  { field: "duration", headerName: "Duration", width: 100 },
  { field: "price", headerName: "Price", width: 100 },
  { field: "price_unit", headerName: "price Unit", width: 100 },
  { field: "start_time", headerName: "Start Time", width: 300 },
  { field: "end_time", headerName: "End Time", width: 300 },
  { field: "status", headerName: "Status", width: 100 },
]
