import { DataGrid } from "@mui/x-data-grid";
const List = (props) => {
  return (
    <div className="datatable">
      <DataGrid
        className="datagrid"
        rows={props.data}
        columns={props.columns}
        pageSize={50}
        rowsPerPageOptions={[50]}
      />
    </div>
  );
};

export default List;
