import { useContext, useState } from "react";
import "./login.scss";
import { signInWithEmailAndPassword,  sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

const Login = () => {
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [resetPasswordRequested, setResetPasswordRequested] = useState(false); // New state variable

  const navigate = useNavigate();

  const { dispatch } = useContext(AuthContext);

  const handleLogin = (e) => {
    e.preventDefault();

    // Check if the user is an admin before signing in
    if (email === "workkashif574@gmail.com" || email === "alexandre@my-carrat.com" || email === "bhattigulfamasif@gmail.com") {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          dispatch({ type: "LOGIN", payload: user });
          navigate("/");
        })
        .catch((error) => {
          setError(true);
        });
    } else {
      setError(true);
    }
  };

  const handleResetPassword = () => {
    // Send a password reset email to the user's provided email address
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setResetPasswordRequested(true);
      })
      .catch((error) => {
        setError(true);
      });
  };

  return (
    <div className="login">
      {resetPasswordRequested ? (
        <div>
          <p>Check your email for a password reset link.</p>
          <button onClick={() => setResetPasswordRequested(false)}>Go back</button>
        </div>
      ) : (
        <form onSubmit={handleLogin}>
          <input
            type="email"
            placeholder="email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit" className="login-btn">Login</button>

          <button type="button" onClick={handleResetPassword}  className="forgot-password-btn">
            Forgot Password?
          </button>
          {error && <span>Wrong email or password!</span>}
        </form>
      )}
    </div>

  );
};

export default Login;
