import "./single.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import List from "../../components/table/Table";
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { lastCallsColums, recordingsColumns } from "../../datatablesource";// Import your Firebase configuration
import { doc,
  getDoc,
  updateDoc,
  collection,
  query, // Import the query function
  where, // Import the where function
  onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';

const Single = () => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [callData, setCallData] = useState([]);
  const [recordingData, setRecordingData] = useState([]);

  const handleAccountActivationToggle = async (id) => {
    try {
      const userRef = doc(db, "users", id);
      const userData = await getDoc(userRef);

      if (userData.exists()) {
        const currentActiveValue = userData.data().active;

        const confirmationMessage = currentActiveValue
          ? "Do you want to deactivate this account?"
          : "Do you want to activate this account?";

        const confirmation = window.confirm(confirmationMessage);

        if (confirmation) {
          await updateDoc(userRef, {
            active: !currentActiveValue,
          });
        } else {
          console.log("User canceled the operation.");
        }
      } else {
        console.warn(`Document with ID ${id} does not exist.`);
      }
    } catch (error) {
      console.log("Error updating Active status:", error);
    }
  }


  useEffect(() => {
    const docRef = doc(db, 'users', userId);

    const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        setUser(docSnapshot.data());
      } else {
        console.log('User not found');
      }
    });

    return () => unsubscribe();
  }, [userId]);

  useEffect(() => {
    // LISTEN (REALTIME)
    const unsub = onSnapshot(
      collection(db, "users", `${userId}`, 'calls'),
      (snapShot) => {
        let list = [];
        snapShot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setCallData(list);
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };

  }, [userId]);

  useEffect(() => {
    // LISTEN (REALTIME) for recordings
    const unsub = onSnapshot(
      query(collection(db, "recordings"), where("uid", "==", userId)), // Adjust the collection and query
      (snapShot) => {
        let list = [];
        snapShot.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setRecordingData(list); // Update state with recordings data
      },
      (error) => {
        console.log(error);
      }
    );

    return () => {
      unsub();
    };
  }, [userId]);

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="single">
      {/* Your components for Sidebar and Navbar */}
      <Sidebar />
      <div className="singleContainer">
        {/* Your components for the top section */}
        <Navbar />
        <div className="top">
          <div className="left">
            <h1 className="title">Information</h1>
            <div className="item">
              {/* Display user information */}
               {/* <img
                src="https://images.pexels.com/photos/733872/pexels-photo-733872.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
                alt=""
                className="itemImg"
              /> */}
              <div className="details">
              <h1 className="itemTitle">{user.name}</h1>
              <div className="detailItem">
                <span className="itemKey">Email:</span>
                <span className="itemValue">{user.email}</span>
              </div>
              <div className="detailItem">
                <span className="itemKey">Phone:</span>
                <span className="itemValue">{user.phone}</span>
              </div>
              <div className="detailItem">
                <span className="itemKey">User Language:</span>
                <span className="itemValue">{user.user_language}</span>
              </div>
              <div className="detailItem">
                <div className="cellAction">
                  <label className="switch">
                    <input type="checkbox"
                    className="input"
                    checked={user.active} // Set the checkbox state
                    onChange={()=>handleAccountActivationToggle(userId)}
                    ></input>
                    <span className="slider"></span>
                  </label>
                </div>
              </div>
            </div>
            </div>
          </div>
          {/* Right section */}
           {/* <div className="right">
            <Chart aspect={3 / 1} title="User Spending ( Last 6 Months)" />
          </div> */}
        </div>
        <div className="bottom">
          {/* Your components for the bottom section */}
          <h1 className="title">Recordings</h1>
          <List data={recordingData} columns={[
          ...recordingsColumns,
            {
              field: "audio",
              headerName: "Audio",
              width: 310,
              renderCell: (params) => {
                return (
                  <div>
                    <audio controls>
                      <source src={params.row.url} type="audio/mpeg" />
                      Your browser does not support the audio element.
                    </audio>
                  </div>
                );
              },
            },
            // {
            //   field: "actions",
            //   headerName: "Actions",
            //   width: 150,
            //   renderCell: (params) => {
            //     return (
            //       <div>
            //         <button onClick={() => handleDelete(params.row.id)}>Delete</button>
            //       </div>
            //     );
            //   },
            // },
          ]}/>
        </div>
        <div className="bottom">
          {/* Your components for the bottom section */}
          <h1 className="title">Last Calls</h1>
          <List  data={callData} columns={lastCallsColums}/>
        </div>
      </div>
    </div>
  );
};

export default Single;
